import { render, staticRenderFns } from "./LotsToSendTable.vue?vue&type=template&id=2aebc46e&scoped=true"
import script from "./LotsToSendTable.vue?vue&type=script&lang=js"
export * from "./LotsToSendTable.vue?vue&type=script&lang=js"
import style0 from "@/assets/scss/table.scss?vue&type=style&index=0&prod&lang=scss&external"
import style1 from "./LotsToSendTable.vue?vue&type=style&index=1&id=2aebc46e&prod&lang=scss"
import style2 from "./LotsToSendTable.vue?vue&type=style&index=2&id=2aebc46e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2aebc46e",
  null
  
)

export default component.exports